import React, { MouseEvent } from 'react'
import styles from './matricula.module.scss'
import { t } from '../../../common/i18n'

interface MatriculaProps {
  matricula: string
  title?: string
  onClick: () => void
}

export default function Matricula({
  matricula,
  title,
  onClick,
}: MatriculaProps) {
  return (
    <div className={styles.revisionMotoMatricula}>
      {title && <h4>{title}</h4>}

      <div className={styles.revisionMotoMatricula__form}>
        <input
          className={styles.revisionMotoMatricula__form_input}
          type="text"
          id="matricula"
          name="matricula"
          value={matricula}
          disabled={true}
          placeholder={matricula}
        />
        <button
          className={styles.revisionMotoMatricula__form_button}
          onClick={(e: MouseEvent<HTMLButtonElement>) => {
            e.preventDefault()
            onClick()
          }}>
          {t('matricula.editar')}
        </button>
      </div>
    </div>
  )
}
