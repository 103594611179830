import React, { MouseEvent, useContext, useState } from 'react'
import useForm from 'react-hook-form'
import * as yup from 'yup'
import * as AltaVehiculoActions from '../../context/actions/altaVehiculoActions'
import * as RevisionActions from '../../context/actions/revisionActions'
import AppContext, { IAppState } from '../../context/context'
import { t } from '../../i18n'
import SelectorFooterDesktop from '../FunnelSelector/Footers/SelectorFooterDesktop'
import InputCesta from '../inputCesta/InputCesta'
import SpinnerWithText from '../spinnerWithText/SpinnerWithText'
import styles from './index.module.scss'
import { matricula, normalizeMatricula } from '../../constants/matricula'
import { ValidateCP } from '../../../specific/helpers/validation/validationCP'
import MatriculaIcon from '../matriculaIcon/MatriculaIcon'
import { connect } from 'react-redux'
import {
  checkComingFromHome,
  getVehicleInfo,
} from '../../context/selectors/revisionSelector'
import { Vehicle } from '../../types/Vehiculo'
import {
  startFindOil,
  startOilJourney,
} from '../../context/actions/revisionActions'
import Matricula from '../../../shared/components/matricula/matricula'

const elpaso = process.env.GATSBY_WEB === 'elpaso'
interface CarInfoProps {
  vehicle: Vehicle | null
  isFromHome: boolean
  goToPreviousStep: () => void
}

const CarInfoDataFields = {
  ...matricula,
  kms: yup
    .number()
    .typeError(t('validations.kms.isNum'))
    .moreThan(0, t('validations.kms.moreThan'))
    .required(t('validations.kms.required')),
  postCode: yup
    .string()
    .test(
      'test-codigopostal',
      t('validations.form_facturacion.postalCode.invalid'),
      (cp) => ValidateCP(cp)
    ),
}
interface InCarInfoDataFields {
  matricula: string
  kms: number
  postCode: string
}

const carInfoFormSchema = yup.object().shape(CarInfoDataFields)

const CarInfoStep = ({
  vehicle,
  isFromHome,
  goToPreviousStep,
}: CarInfoProps) => {
  const {
    altaVehiculoState,
    dispatch,
    userInfo: { postCode },
  } = useContext(AppContext)

  const defaultPostCode = elpaso ? '35001' : ''
  const [editMatricula, setEditMatricula] = useState(!vehicle)
  const { loading } = altaVehiculoState
  const { register, handleSubmit, errors } = useForm<InCarInfoDataFields>({
    mode: 'onSubmit',
    validationSchema: carInfoFormSchema,
  })

  const onSubmit = (data) => {
    if (vehicle && vehicle.cod_matricula !== data.matricula) {
      dispatch(RevisionActions.resetRevisiones())
      vehicle = null
    }
    data.matricula = normalizeMatricula(data.matricula)
    const { matricula, kms, postCode } = data
    dispatch(RevisionActions.setMatriculaDistance(matricula, kms, postCode))
    if (vehicle) {
      dispatch(startFindOil())
    } else {
      dispatch(AltaVehiculoActions.retrieveVehicleInfo(matricula))
    }
  }

  if (!loading && altaVehiculoState.error) {
    dispatch(AltaVehiculoActions.resetAltaVehiculosError())
    dispatch(AltaVehiculoActions.setAltaVehiculoStep(5))
    dispatch(startOilJourney())
  }

  if (!loading && altaVehiculoState.vehicleInformation) {
    dispatch(startOilJourney())
  }
  const onClick = () => {
    setEditMatricula(true)
  }
  return (
    <div className={styles.personaldata_container}>
      <div className={styles.form_container}>
        <div className={styles.title}>
          {!editMatricula ? (
            <React.Fragment>
              <p className={styles.matricula_title}>
                <Matricula
                  matricula={vehicle.cod_matricula}
                  onClick={onClick}
                />
              </p>
              {t('revisiones.step1_titulo_matricula')}
            </React.Fragment>
          ) : (
            t('revisiones.step1_titulo')
          )}
        </div>

        <form className={styles.login__form} onSubmit={handleSubmit(onSubmit)}>
          {loading ? (
            <SpinnerWithText
              loading={loading}
              text={t('revisiones.step1_waiting')}
            />
          ) : (
            <div>
              <div
                className={
                  editMatricula
                    ? styles.matricula_input_show
                    : styles.matricula_input_hidden
                }>
                <InputCesta
                  label={t('revisiones.step1_matricula')}
                  name="matricula"
                  defaultValue={vehicle?.cod_matricula || ''}
                  refe={register}
                  errors={errors}
                  withInfoIcon
                  infoIconText={t('alta_vehiculo.tooltip_matricula')}
                />
                <div className={styles.inputmessage}>
                  {' '}
                  {t('talleres.con-matricula')}
                </div>
              </div>
              <InputCesta
                label={t('revisiones.step1_kilometros')}
                name="kms"
                refe={register}
                errors={errors}
              />
              <div className={postCode || elpaso ? styles.hide : ''}>
                <InputCesta
                  label={t('revisiones.step1_codigo_postal')}
                  name="postCode"
                  refe={register}
                  errors={errors}
                  defaultValue={postCode || defaultPostCode}
                />
              </div>
            </div>
          )}

          <SelectorFooterDesktop
            continueButton={true}
            onGoBack={isFromHome ? goToPreviousStep : undefined}
          />
        </form>
      </div>
    </div>
  )
}

const mapStateToProps = (state: IAppState) => ({
  vehicle: getVehicleInfo(state),
  isFromHome: checkComingFromHome(state),
})
export default connect(mapStateToProps)(CarInfoStep)
