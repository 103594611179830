import React, { useContext } from 'react'
import Swal from 'sweetalert2'
import { setAltaVehiculoStep } from '../../context/actions/altaVehiculoActions'
import AppContext from '../../context/context'
import { t } from '../../i18n'
import { alertProp } from '../../utils/swal'
import SpinnerWithText from '../spinnerWithText/SpinnerWithText'
import styles from './VehicleFinderRevisiones.module.scss'
import VehicleFinderStepFive from './vehicleFinderStepFive/VehicleFinderStepFive'
import VehicleFinderStepFour from './vehicleFinderStepFour/VehicleFinderStepFour'
import VehicleFinderStepSix from './vehicleFinderStepSix/VehicleFinderStepSix'
import VehicleFinderStepTwo from './vehicleFinderStepTwo/VehicleFinderStepTwo'
import {
  resetRevisionError,
  resetRevisionSuccess,
  setVehicleInfo,
  startOilJourney,
} from '../../context/actions/revisionActions'

interface VehicleFinderRevisionesProps {
  goToNextStep: () => void
  goToPreviousStep: () => void
}

export default function VehicleFinderRevisiones({
  goToNextStep,
  goToPreviousStep,
}: VehicleFinderRevisionesProps) {
  const { revisionState, altaVehiculoState, dispatch } = useContext(AppContext)
  const { step } = altaVehiculoState

  const onSelectVehicle = async (vehicle) => {
    dispatch(setVehicleInfo(vehicle))
    dispatch(startOilJourney())
  }

  if (revisionState.success) {
    dispatch(resetRevisionSuccess())
  }

  if (revisionState.error) {
    Swal.fire(
      alertProp({
        type: 'error',
        title: 'Oops...',
        text: revisionState.error,
      })
    )
    dispatch(resetRevisionError())
  }

  const goToStep = (step) => {
    dispatch(setAltaVehiculoStep(step))
  }
  return (
    <div className={styles.alta_vehiculo_container}>
      {step === 0 && null}
      {altaVehiculoState.loading && (
        <SpinnerWithText text={t('alta_vehiculo.cargando')} />
      )}
      {!altaVehiculoState.loading && step === 2 && (
        <VehicleFinderStepTwo
          origin="revision"
          goToStep={goToStep}
          revisionesSubmit={onSelectVehicle}
          goToPreviousStep={goToPreviousStep}
        />
      )}
      {!altaVehiculoState.loading && step === 4 && (
        <VehicleFinderStepFour
          origin="revision"
          goToStep={goToStep}
          goToPreviousStep={goToPreviousStep}
        />
      )}
      {!altaVehiculoState.loading && step === 5 && (
        <VehicleFinderStepFive
          goToStep={goToStep}
          goToNextStep={goToNextStep}
          origin="revision"
          goToPreviousStep={goToPreviousStep}
        />
      )}
      {!altaVehiculoState.loading && step === 6 && (
        <VehicleFinderStepSix
          origin="revision"
          status={revisionState.status}
          combustible={revisionState.combustible}
        />
      )}
    </div>
  )
}
