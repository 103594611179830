import { graphql, navigate } from 'gatsby'
import React, { useContext, useEffect } from 'react'
import { DataSteps, Steps } from '../../../components/cesta/Steps'
import FunnelSideBar from '../../../components/funnelSideBar/FunnelSideBar'
import Layout from '../../../components/Layout'
import LayoutSelector from '../../../components/layouts/layoutSelector/LayoutSelector'
import MainFormContainer from '../../../components/layouts/mainFormContainer/MainFormContainer'
import CarInfoStep from '../../../components/revisiones/CarInfoStep'
import OilInfoStep from '../../../components/revisiones/OilInfoStep'
import ServicesStep from '../../../components/revisiones/ServicesStep'
import SpinnerWithText from '../../../components/spinnerWithText/SpinnerWithText'
import VehicleFinderRevisiones from '../../../components/vehicleFinder/VehicleFinderRevisiones'
import * as AltaVehiculoActions from '../../../context/actions/altaVehiculoActions'
import * as CestaActions from '../../../context/actions/cestaActions'
import * as RevisionActions from '../../../context/actions/revisionActions'
import AppContext from '../../../context/context'
import { t } from '../../../i18n'
import { SeoData } from '../../../types/Seo'
import Size from '../../../utils/media-queries-setup'
import route from '../../../utils/route'

const dataSteps = [
  {
    step: 1,
    name: 'revisiones.step1_label',
  },
  {
    step: 2,
    name: 'revisiones.step_alta_vehiculo',
  },
  {
    step: 3,
    name: 'revisiones.step2_label',
  },
  {
    step: 4,
    name: 'revisiones.step3_label',
  },
]

const isMatricula = (mat) =>
  /^([0-9]{4}(-| |)[A-Za-z]{3}|[A-Za-z]{1,2}?(-| |)[0-9]{4}(-| |)[a-zA-Z]{1,2}?)$/.test(
    mat
  )

interface Props {
  data: {
    seoData: SeoData
  }
}

export default function AsistenteRevisiones({ data }: Props) {
  const { dispatch, revisionState, altaVehiculoState } = useContext(AppContext)

  const dispositivo = Size.useMedia({
    mobile: 'MOBILE',
    tablet: 'TABLET',
    desktop: 'DESKTOP',
  }) as 'MOBILE' | 'TABLET' | 'DESKTOP'

  const { step } = revisionState

  useEffect(() => {
    return () => {
      dispatch(AltaVehiculoActions.resetAltaVehiculos())
    }
  }, [])

  useEffect(() => {
    const query = new URLSearchParams(document.location.search)
    const matricula = query.get('mat')
    const aceite = query.get('oil')
    const kms = query.get('kms')
    const postCode = query.get('cp')
    // If no postCode specified by default we assume not Galicia
    // Kms are optional since we already have the oil
    if (
      matricula &&
      isMatricula(matricula) &&
      aceite &&
      ((kms && !isNaN(Number(kms))) || !kms)
    ) {
      dispatch(RevisionActions.setRevisionLoading())
      dispatch(
        RevisionActions.verifyPresetRevisiones(
          matricula,
          aceite,
          kms,
          postCode,
          dispositivo
        )
      )
    } else if (matricula || aceite || kms) {
      // if there's any of them but conditions above are not met, then send to initial step
      // otherwise, just proceed with normal revision steps
      dispatch(AltaVehiculoActions.resetAltaVehiculos())
      dispatch(RevisionActions.resetRevisiones())
      dispatch(RevisionActions.setRevisionStep(1))
    }
  }, [])

  const insideAltaVehiculo = () => {
    return revisionState.step === 2
  }

  const shouldRestart = () => {
    if (revisionState.step === 2 && altaVehiculoState.step === 2) {
      return true
    }
    return (
      [3, 4].includes(revisionState.step) &&
      revisionState.comingFrom === 'area-privada'
    )
  }

  const goToNextStep = () => {
    dispatch(RevisionActions.setRevisionStep(step + 1))
  }

  const goToPreviousStep = async () => {
    if (step === 1) {
      await navigate(route('index'))
      return null
    }
    if (shouldRestart()) {
      dispatch(AltaVehiculoActions.resetAltaVehiculos())
      dispatch(RevisionActions.resetRevisiones())
      dispatch(RevisionActions.setRevisionStep(1))
      return null
    }

    if (insideAltaVehiculo()) {
      if (altaVehiculoState.step === 4) {
        dispatch(AltaVehiculoActions.setAltaVehiculoStep(2))
        return null
      }
      dispatch(RevisionActions.setRevisionStep(1))
    }

    dispatch(RevisionActions.setRevisionStep(step - 1))
  }

  const onSelectRevision = async (id) => {
    const selectedRevision = revisionState.revisiones.find(
      (item) => item.site_producto.sku === id
    )
    dispatch(CestaActions.setSelectedRevision(selectedRevision))
    dispatch(RevisionActions.setRevision(id))
    dispatch(
      CestaActions.addProduct(
        {
          id,
          type: 'revision',
          revisionPlan: selectedRevision.tipo,
        },
        'revisiones',
        revisionState.vehiculoInfo
      )
    )
    dispatch(CestaActions.setStep(Steps.TU_SOLICITUD))
    await navigate(DataSteps[Steps.TU_SOLICITUD].route)
  }

  const getModeloTitle = () => {
    const vehicle = revisionState.vehiculoInfo
    return `${[vehicle.modelo, vehicle.motor, vehicle.combustible_tipo]
      .filter(Boolean)
      .join(' ')}`
  }
  return typeof window === 'undefined' ? null : (
    <Layout seoData={data.seoData} selector={true} onGoBack={goToPreviousStep}>
      <LayoutSelector
        stepActive={step}
        mobileStep={step}
        totalSteps={4}
        title={t('revisiones.revision')}
        subTitle={t('revisiones.tu_vehiculo')}
        subTitle2={
          step > 2
            ? t('revisiones.tu_vehiculo_para', {
                modelo: getModeloTitle(),
              })
            : null
        }
        customSteps={dataSteps}>
        <MainFormContainer>
          {revisionState.loading && (
            <SpinnerWithText text={t('revisiones.cargando')} />
          )}
          {!revisionState.loading && step === 1 ? (
            !altaVehiculoState.loading ? (
              <CarInfoStep goToPreviousStep={goToPreviousStep} />
            ) : (
              <SpinnerWithText text={t('revisiones.cargando')} />
            )
          ) : null}
          {!revisionState.loading && step === 2 ? (
            <VehicleFinderRevisiones
              goToPreviousStep={goToPreviousStep}
              goToNextStep={goToNextStep}
            />
          ) : null}
          {!revisionState.loading && step === 3 ? (
            <OilInfoStep
              goToNextStep={goToNextStep}
              goToPreviousStep={goToPreviousStep}
            />
          ) : null}
          {!revisionState.loading && step === 4 ? (
            <ServicesStep
              onSelectRevision={onSelectRevision}
              goToPreviousStep={goToPreviousStep}
            />
          ) : null}
        </MainFormContainer>
        {step === 4 ? <FunnelSideBar revisionInfo /> : <FunnelSideBar />}
      </LayoutSelector>
    </Layout>
  )
}

export const pageQuery = graphql`
  query asistenteRevisionesQuery($url: String!) {
    seoData: pageInfo(url: { eq: $url }) {
      ...seoinformation
    }
  }
`
