import React, { useContext, useEffect, useState } from 'react'
import useForm from 'react-hook-form'
import Swal from 'sweetalert2'
import { registroLeadMatricula } from '../../api/reports'
import {
  resetRevisionLoading,
  setOilSelection,
  setRevisionLoading,
} from '../../context/actions/revisionActions'
import AppContext from '../../context/context'
import dataLayer from '../../helpers/functions/dataLayer'
import { t, textWithLocale } from '../../../common/i18n'
import logic from '../../logic'
import Size from '../../utils/media-queries-setup'
import { alertProp } from '../../utils/swal'
import SelectorFooterDesktop from '../FunnelSelector/Footers/SelectorFooterDesktop'
import RadioButtonYellow from '../radioButtonYellow/RadioButtonYellow'
import Recomendado from '../Recomendado/Recomendado'
import styles from './index.module.scss'
import route from '../../utils/route'
import { navigate } from 'gatsby'

export default function OilInfoStep({ goToNextStep, goToPreviousStep }) {
  const { revisionState, dispatch, userInfo } = useContext(AppContext)
  const { handleSubmit } = useForm()
  const { listaAceites, aceiteSeleccionado } = revisionState
  const [idAceite, setIdAceite] = useState(aceiteSeleccionado)

  useEffect(() => {
    dataLayer.push({
      event: 'page_view',
      route: '/revisiones/asistente/aceite',
      title: document.title,
    })
  }, [])

  const dispositivo = Size.useMedia({
    mobile: 'MOBILE',
    tablet: 'TABLET',
    desktop: 'DESKTOP',
  }) as 'MOBILE' | 'TABLET' | 'DESKTOP'

  const handleChangeOil = (value) => {
    setIdAceite(value)
  }
  if (listaAceites.length === 0) {
    navigate(route('cita-previa.seleccion'))
  }
  const oilRadioButtons = listaAceites.map((oil) => (
    <div key={oil.id_site_revision_aceite} className={styles.radioButtonItem}>
      <RadioButtonYellow
        onChange={() => handleChangeOil(oil.id_site_revision_aceite)}
        checked={oil.id_site_revision_aceite === idAceite}
        label1={
          <>
            <div className={styles.label_title}>
              {oil[textWithLocale('titulo')]}

              {oil.precio &&
                `${t('revisiones.step3_desde')} ${(
                  oil.precio *
                  (oil.tipo_iva / 100 + 1)
                ).toFixed(2)}€`}
            </div>
            {oil.homologationCode && (
              <div className={styles.label_title}>
                {`${t('revisiones.step3_homologacion')}: ${
                  oil.homologationCode
                }`}
              </div>
            )}
          </>
        }
        label2={
          <div className={styles.label_subtitle}>
            {oil[textWithLocale('descripcion')]}
          </div>
        }
        name={oil.tipo_aceite}
        value={oil.id_site_revision_aceite}
      />
    </div>
  ))

  const onSubmit = async () => {
    dataLayer.push({
      event: 'elegir_aceite',
      aceite_recomendado:
        listaAceites.find((item) => item.id_site_revision_aceite === idAceite)
          .recomendado === 1,
      numero_aceites: listaAceites.length,
    })
    try {
      dispatch(setRevisionLoading())
      const revisiones = await logic.getRevisionesList(
        idAceite,
        userInfo.postCode || revisionState.postCode
      )
      dispatch(resetRevisionLoading())
      dispatch(setOilSelection(idAceite, revisiones))
      const aceiteSeleccionado = revisionState.listaAceites.find(
        (item) => item.id_site_revision_aceite === idAceite
      )
      const dataRegisterLeadMatricula = {
        matricula: revisionState.matricula,
        aceite_escogido: aceiteSeleccionado.tipo_aceite,
        dispositivo,
        id_site_usuario: userInfo.idUser,
        postCode: userInfo.postCode || revisionState.postCode,
      }
      registroLeadMatricula(dataRegisterLeadMatricula)
      goToNextStep()
    } catch ({ message }) {
      Swal.fire(
        alertProp({
          type: 'error',
          title: 'Oops...',
          text: message,
        })
      )
    }
  }

  return (
    <div className={styles.personaldata_container}>
      <div className={styles.form_container}>
        <div className={styles.title}>
          {t(
            'revisiones.step2_titulo'.concat(
              listaAceites.length === 1 ? '1' : '2'
            )
          )}
        </div>
        <form className={styles.login__form} onSubmit={handleSubmit(onSubmit)}>
          <div className={styles.checkboxes_container}>{oilRadioButtons}</div>
          <SelectorFooterDesktop
            continueButton={true}
            onGoBack={goToPreviousStep}
          />
        </form>
      </div>
    </div>
  )
}
